<template>
  <h3 class="title">
    <span class="span"><slot></slot></span>
  </h3>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.title {
  padding: 50px 0;
  display: flex;
  justify-content: center;

  .span {
    position: relative;
    display: inline-block;
    font-size: 22px;
    color: #333333;
    font-weight: 500;
    padding: 0 30px;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      height: 2px;
      width: 250px;
      background: linear-gradient(63deg, #ffffff, #2e80ff);
      transform: traslate(0, -50%);
      opacity: 0.5;
    }

    &::after {
      right: auto;
      left: 100%;
      background: linear-gradient(63deg, #2e80ff, #ffffff);
    }
  }
}
</style>
