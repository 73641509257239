<template>
  <div class="leftSidebar">
    <div class="title">
      <img :src="data.icon" alt="" class="uiImg" />{{ $t(data.title) }}
    </div>
    <div
      v-for="(item, index) of data.list"
      :key="index"
      class="item"
      :class="{ active: activeIndex === index }"
      @click="change(item, index)"
    >
      {{ $t(item.name) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftSidebar',
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  computed: {},
  watch: {
    '$route.path': {
      handler(newVal, oldVal) {
        const list = this.data.list
        if (list && list.length) {
          for (let index = 0; index < list.length; index++) {
            const element = list[index]
            const path = element.path
            if (path && Array.isArray(path) && path.includes(newVal)) {
              this.activeIndex = index
            } else
            if (path && newVal.includes(path)) {
              this.activeIndex = index
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    change(item, index) {
      this.activeIndex = index
      const path = item.path
      if (path && typeof (path) === 'string') {
        this.$router.push({
          path: path,
        })
      } else if (path && Array.isArray(path)) {
        this.$router.push({
          path: path[0],
        })
      } else if (item.event) {
        this.$emit(item.event, item.value, item, index)
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.leftSidebar {
  width: 260px;
  background: #ffffff;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.08);
  .title {
    display: flex;
    align-items: center;
    height: 36px;
    background: #f9f9f9;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    .uiImg {
      width: 10px;
      height: 10px;
      margin: 0 10px;
    }
  }

  .item {
    position: relative;
    margin-left: 30px;
    padding: 24px 0;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
    cursor: pointer;
  }
  .active {
    color: @uni-text-color-select;
    &::after {
      position: absolute;
      right: 2px;
      content: '';
      width: 2px;
      height: 14px;
      background: @uni-text-color-select;
      border-radius: 2px;
    }
  }
}
</style>
