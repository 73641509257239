<template>
  <div class="box">
    <!-- header -->
    <div class="header">
      <h2 class="title">{{ $t('dd315c1') }}</h2>
      <p class="sub-title">
        {{ $t('5a93d74') }}
      </p>
    </div>

    <!-- cards -->
    <div class="cards">
      <div class="card">
        <div class="logo logo1"></div>
        <div class="cont">
          <h4 class="title">平台愿景</h4>
          <p class="desc">
            打造互联网+涉外法务服务体系，紧紧围绕涉外企业与涉外法务服务
          </p>
        </div>
      </div>
      <div class="card">
        <div class="logo logo2"></div>
        <div class="cont">
          <h4 class="title">平台使命</h4>
          <p class="desc">
            实现为“一带一 路”参与各方的涉外企业提供涉外司法咨询与服务
          </p>
        </div>
      </div>
    </div>

    <DecorateTitle>平台运营阶段规划</DecorateTitle>

    <!-- timeline -->
    <div class="timeline">
      <div v-for="item in data" :key="item.date" class="item">
        <div class="head">
          <div class="tag">{{ item.tag }}</div>
          <div class="date">{{ item.date }}</div>
        </div>
        <p class="desc">
          {{ item.desc }}
        </p>
        <img class="cover" :src="item.img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import DecorateTitle from './components/DecorateTitle'
export default {
  components: {
    DecorateTitle,
  },
  data() {
    return {
      data: [
        {
          tag: '试点运营',
          date: '2020-03',
          desc:
            '搭建平台、聚合资源、试 点先行、完成平台框架搭 建，关联基础业务，整合涉外司法资源，试点先行',
          img: require('@/assets/about/intro3.png'),
        },
        {
          tag: '国内试点',
          date: '2020-05',
          desc: '全面整合“一带一路”涉外法务资源，实现全国范围内 的辐射效应',
          img: require('@/assets/about/intro4.png'),
        },
        {
          tag: '全面布局',
          date: '2020-12',
          desc:
            '依托“一带一路” 商贸合作将平台业务拓展到“一带 一路” 参与各国，开展全 面服务',
          img: require('@/assets/about/intro5.png'),
        },
        {
          tag: '全面推广',
          date: '2021-05',
          desc: '形成青岛新标志，成为青岛 产业新高地',
          img: require('@/assets/about/intro6.png'),
        },
      ],
    }
  },
}
</script>

<style scoped lang="less">
.header {
  text-align: center;
  padding: 50px 0 30px;

  .title {
    font-size: 26px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .sub-title {
    font-size: 15px;
    color: #8c939e;
  }
}

.cards {
  padding: 30px;
  display: flex;
  .card {
    flex: 1;
    margin-right: 20px;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    height: 130px;
    padding: 0 20px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    .logo {
      margin-right: 19px;
    }
    .logo1 {
      width: 126px;
      height: 119px;
      background: url('~@/assets/about/intro1.png') center;
      background-size: 126px 119px;
    }
    .logo2 {
      width: 129px;
      height: 131px;
      background: url('~@/assets/about/intro2.png') center;
      background-size: 129px 131px;
    }

    .cont {
      flex: 1;
    }

    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .desc {
      font-size: 13px;
      color: #8c939e;
      line-height: 20px;
    }
  }
}

.timeline {
  position: relative;
  padding: 110px 0 20px 30px;
  display: flex;
  flex-flow: wrap;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 40px;
    bottom: 190px;
    border-right: 1px solid #eee;
  }

  .item {
    position: relative;
    width: 390px;
    height: 402px;
    border-radius: 8px;
    border: 1px solid #eee;
    margin-bottom: 40px;

    &::before {
      content: '';
      position: absolute;
      top: 40px;
      left: 100%;
      width: 40px;
      height: 1px;
      background: #eee;
    }

    &::after {
      content: '';
      position: absolute;
      top: 40px;
      left: 100%;
      width: 12px;
      height: 12px;
      background: linear-gradient(90deg, #5397ff 0%, #ebf2fd 100%);
      margin-left: 40px;
      border-radius: 50px;
      overflow: hidden;
      transform: translate(-50%, -50%);
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;

      .tag {
        width: 114px;
        height: 36px;
        line-height: 36px;
        border-radius: 0 100px 100px 0;
        text-align: center;
        background: rgba(#008bff, 0.07);
        color: #008bff;
        font-size: 18px;
        font-weight: 500;
      }

      .date {
        padding: 0 25px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }

    .desc {
      padding: 8px 0;
      margin: 0 20px 12px;
      color: #7b8492;
      font-size: 14px;
      line-height: 22px;
    }

    .cover {
      display: block;
      margin: 0 auto;
      width: 340px;
      height: 230px;
    }
  }

  .item:nth-child(odd) {
    margin-right: 78px;
    transform: translate(0, -110px);
  }

  .item:nth-child(even) {
    .head {
      .tag {
        order: 2;
        border-radius: 100px 0 0 100px;
      }
    }
    &::before {
      left: auto;
      right: 100%;
    }
    &::after {
      left: auto;
      right: 100%;
      margin-left: 0;
      margin-right: 40px;
      transform: translate(50%, -50%);
    }
  }
}
</style>
