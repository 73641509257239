<template>
  <div class="entry">
    <DecorateTitle>{{ $t('3a014ca') }}</DecorateTitle>
    <div class="article">
      <section>
        <h4>
          <img src="@/assets/about/entry1.png" alt="" />
          <span>{{ $t('cac4aa8') }}</span>
        </h4>
        <p>{{ $t('1c10e64') }}</p>
      </section>
      <section>
        <h4>
          <img src="@/assets/about/entry2.png" alt="" />
          <span>{{ $t('fd5aa83') }}</span>
        </h4>
        <p>{{ $t('c04b959') }}</p>
      </section>
      <section>
        <h4>
          <img src="@/assets/about/entry3.png" alt="" />
          <span>{{ $t('801f782') }}</span>
        </h4>
        <p>{{ $t('8299a21') }}</p>
      </section>
      <section>
        <h4>
          <img src="@/assets/about/entry4.png" alt="" />
          <span>{{ $t('9f3958b') }}</span>
        </h4>
        <!-- <p>
          1.
          <a href="#">《青岛市涉外法律服务产业园入驻申请表》</a>
          （点击下载）一份。
        </p> -->
        <p> {{ $t('918f431') }} </p>
        <!--        <p>-->
        <!--          2.提交上一年度税务部门出具的缴税情况证明以及会计师事务所出具的上一年度审计报告复印件（加盖公章）。-->
        <!--        </p>-->
        <!--        <p>-->
        <!--          3.法定代表人或负责人身份证原件及复印件一份；委托他人办理，则须明确委托事件，受托人的身份证及复印件一份。-->
        <!--        </p>-->
        <!--        <p>4.企业宣传简介及图片。</p>-->
        <!--        <p>5.企业宣传视频相关资料。</p>-->
        <!--        <p>6.其他相关资料</p>-->
        <p>
          <span style="margin: 0 36px 0 8px">{{ $t('08878fc') }}：0532-55591980</span>
          <span>地址：山东省青岛市胶州市上合示范区长江路2号</span>
        </p>
      </section>
    </div>
  </div>
</template>

<script>
import DecorateTitle from './components/DecorateTitle'
export default {
  components: {
    DecorateTitle,
  },
  data() {
    return {
      icon1: require('@/assets/about/icon.png'),
    }
  },
}
</script>

<style lang="less" scoped>
.article {
  padding: 0 110px 20px;

  section {
    padding-bottom: 30px;
  }

  h4 {
    display: flex;
    align-items: center;
    color: #333333;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.1;
    padding: 12px 0;

    img {
      display: block;
      width: 20px;
      height: 20px;
      margin-left: -40px;
      margin-right: 20px;
    }
  }

  p,
  li {
    color: #8c939e;
    font-size: 13px;
    line-height: 23px;
    margin-bottom: 10px;
  }

  a {
    color: #00a4ff;

    &:hover {
      text-decoration: underline;
    }
  }

  li {
    // list-style: square;
    list-style-type: decimal;
  }
}
</style>
