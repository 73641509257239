<template>
  <div class="contact">
    <DecorateTitle>{{ $t('d8f9dfc') }}</DecorateTitle>
    <div class="mapPane">
      <baidu-map
        :scroll-wheel-zoom="true"
        :center="center"
        :zoom="zoom"
        class="mapCover"
        @ready="handler"
      />
      <!-- <div id="container" class="mapCover"></div> -->
      <ul class="content">
        <li>
          <i>
            <img class="ui-img" src="@/assets/about/contact1.png" alt="" />
          </i>
          <span>山东省青岛市胶州市上合示范区长江路2号</span>
        </li>
        <li>
          <i>
            <img class="ui-img" src="@/assets/about/contact2.png" alt="" />
          </i>
          <span>0532-55591980</span>
        </li>
        <li>
          <i>
            <img class="ui-img" src="@/assets/about/contact3.png" alt="" />
          </i>
          <span>0532-55591980</span>
        </li>
        <li>
          <i>
            <img class="ui-img" src="@/assets/about/contact4.png" alt="" />
          </i>
          <span>shfazhigu@163.com</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DecorateTitle from './components/DecorateTitle'
export default {
  components: {
    DecorateTitle,
  },
  data() {
    return {
      center: { lng: 120.108213, lat: 36.172981 },
      zoom: 18,
    }
  },
  methods: {
    handler({ BMap, map, BMapGL }) {
      // handler() {
      // var map = new BMap.Map('container')
      var point = new BMap.Point(this.center.lng, this.center.lat)
      map.centerAndZoom(point, this.zoom)
      // 创建标注
      var marker = new BMap.Marker(point)
      // 将标注添加到地图中
      map.addOverlay(marker)
      // map.setCurrentCity('青岛')
    },
  },
}
</script>

<style lang="less" scoped>
.contact {
  padding-bottom: 43px;
}

.mapPane {
  margin: 0 40px;
  height: 320px;
  box-shadow: 0px 0px 26px 0px rgba(225, 225, 225, 0.5);
  display: flex;

  .mapCover {
    width: 446px;
    height: 320px;
    margin-right: 22px;
  }
  .content {
    flex: 1;
    padding-top: 25px;

    .ui-img {
      display: block;
      width: 100%;
      height: 100%;
    }

    li {
      height: 25px;
      line-height: 25px;
      padding: 21px 0;
      font-size: 17px;
      color: #333333;
    }

    i {
      display: inline-block;
      vertical-align: middle;
      width: 25px;
      height: 25px;
      margin-right: 10px;
      // background: pink;
    }
  }
}
</style>
