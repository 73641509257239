<template>
  <div class="about content">
    <div class="inner">
      <LeftSidebar class="lt" :data="leftData" />
      <div class="cont">
        <conponent :is="componentName" />
      </div>
    </div>
  </div>
</template>
<script>
import Entry from './Entry'
import Intro from './Intro'
import Contact from './Contact'
import LeftSidebar from '@/components/LeftSidebar'
export default {
  name: 'About',
  components: {
    LeftSidebar,
    Entry,
    Intro,
    Contact,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.componentName = to.name
    })
  },
  data() {
    return {
      componentName: 'Intro',
      leftData: {
        icon: require('@/assets/about/icon.png'),
        title: '6972f94',
        list: [
          {
            name: 'a445d55',
            path: '/about/intro',
          },
          {
            name: 'adc83d4',
            path: '/about/entry',
          },
          {
            name: 'd8f9dfc',
            path: '/about/contact',
          },
        ],
      },
    }
  },
  methods: {
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.content {
  width: @uni-width;
  margin: 20px auto;
}
.inner {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
}

.side {
  width: 260px;
  margin-right: 20px;
  background: #fff;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.08);
}

.cont {
  width: 920px;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
}
</style>
